import styled from 'styled-components';
import React from 'react';

// Declare that Breadcrumb accepts ReactNode as children
interface BreadcrumbProps {
  children: React.ReactNode;
}

const Breadcrumb = styled.div<BreadcrumbProps>`
  flex-grow: 1;
  max-height: 32px;
  overflow: hidden;
  margin-right: 20px;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BreadcrumbWithChildren: React.FC<BreadcrumbProps> = ({ children }) => {
  return <Breadcrumb>{children}</Breadcrumb>;
};

export { BreadcrumbWithChildren as Breadcrumb };
export type {BreadcrumbProps};