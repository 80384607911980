import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  Button,
  DangerIcon,
  Field,
  MessageBar,
  Placeholder,
  SectionTitle,
  TextInput,
  UsersIllustration,
} from 'akeneo-design-system';
import styled, { createGlobalStyle } from 'styled-components';
import { PageContent, PageHeader, Section } from '../component/page';

type Settings = {
  client_id: string;
  client_secret: string;
  api_key: string;
};

// TODO: manage environments
//const API_URL = 'http://localhost:8081/settings/bff';
const API_URL = '/settings/bff';

const SettingsPage: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [settings, setSettings] = useState<Settings | null>(null);
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {

    const fetchSettings = async () => {
      try {
        const response = await fetch(API_URL);
        if (response.status === 404) {
          return
        } else if (response.status >= 400) {
          throw Error("invalid response status")
        }

        const settings = await response.json();

        setSettings(settings);
      } catch (e) {
        console.error("Error fetching or parsing data:", e);
        setError("could not fetch data")
      }
    };

    fetchSettings()

  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    fetch(API_URL, {
      method: 'DELETE',
    })
    .then((response) => response.json())
    .then(() => {
      setSettings(null);
      setClientId('');
      setClientSecret('');
      setApiKey('');
      setIsEditing(false);
    })
    .catch((error) => console.error('Error saving settings:', error));
  }

  const handleSaveClick = () => {
    const newSettings = {
      client_id: clientId,
      client_secret: clientSecret,
      api_key: apiKey,
    };

    fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newSettings),
    })
    .then((response) => response.json())
    .then(() => {
      setSettings(newSettings);
      setIsEditing(false);
    })
    .catch((error) => console.error('Error saving settings:', error));
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const maskSecret = (secret: string) => {
    return secret ? `${'.'.repeat(secret.length - 2)}${secret.slice(-2)}` : '';
  };

  return (
    <>
    <GlobalStyle />
    <PageHeader>
      <PageHeader.Breadcrumb>
        <Breadcrumb>
          <Breadcrumb.Step>Settings</Breadcrumb.Step>
          <Breadcrumb.Step>API Credentials</Breadcrumb.Step>
        </Breadcrumb>
      </PageHeader.Breadcrumb>
      <PageHeader.Title>BazaarVoice Connector</PageHeader.Title>
      <PageHeader.Actions>
      { isEditing ? (
        <>
          <Button onClick={handleSaveClick}>Save</Button>
          <Button onClick={handleCancelClick} ghost>Cancel</Button>
        </>
      ) : (
        <>
          <Button onClick={handleEditClick}>Edit</Button>
          {settings && (
            <Button level="danger" onClick={handleDeleteClick}>Delete</Button>
          )}
        </>
      )}
      </PageHeader.Actions>
    </PageHeader>
    <PageContent>
    <Section>
      <SectionTitle>
      <SectionTitle.Title>API Credentials</SectionTitle.Title>
      </SectionTitle>
      <Form>

      {error && (
        <MessageBar
        icon={<DangerIcon />}
        level="error"
        onClose={function noRefCheck(){}}
        title="Error"
        dismissTitle='dismiss'
        >
        {error}
        </MessageBar>
      )}

      { isEditing ? (
        <>
        <FieldSet>
          {
          // Temporarilly disable client ID and client secret
          // It seems that we don't need them
          // TODO: totally remove them if this is confirmed
          false && (
          <>
            <Field label="Client ID">
            <TextInput
              aria-labelledby="Client ID"
              placeholder="Enter your BazaarVoice Client ID"
              type="password"
              value={clientId}
              onChange={(newValue: string) => setClientId(newValue)}
            />
            </Field>

            <Field label="Client Secret">
            <TextInput
              aria-labelledby="Client Secret"
              placeholder="Enter your BazaarVoice Client Secret"
              type="password"
              value={clientSecret}
              onChange={(newValue: string) => setClientSecret(newValue)}
            />
            </Field>
          </>
          )}

          <Field label="API Key">
          <TextInput
            aria-labelledby="API Key"
            placeholder="Enter your BazaarVoice API Key"
            type="password"
            value={apiKey}
            onChange={(newValue: string) => setApiKey(newValue)}
          />
          </Field>
        </FieldSet>

        </>
      ) : !settings ? (
        <>
        <Placeholder
        illustration={<UsersIllustration />}
        title="No API key configured"
        >
        </Placeholder>
        </>
      )
      : (
        <>
        <FieldSet>
          { false && (<>
            <Field label="Client ID">
            <TextInput
              aria-labelledby="Client ID"
              value={maskSecret(settings?.client_id || "")}
              readOnly
            />
            </Field>

            <Field label="Client Secret">
            <TextInput
              aria-labelledby="Client Secret"
              value={maskSecret(settings?.client_secret || "")}
              readOnly
            />
            </Field>
          </>)}

          <Field label="API Key">
          <TextInput
            aria-labelledby="API Key"
            value={maskSecret(settings.api_key)}
            readOnly
          />
          </Field>
        </FieldSet>
        </>
      )}
      </Form>


    </Section>
    </PageContent>

    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    left:0;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  * {
    box-sizing: border-box;
  }
`;

const Form = styled.form`
  margin-bottom: 80px;
`;

const FieldSet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;


export default SettingsPage;
