import styled, {css} from 'styled-components';
import {AkeneoThemedProps} from 'akeneo-design-system';

const PageContent = styled.div<{headerHeight?: number} & AkeneoThemedProps>`
  padding: 0 40px;
  overflow: auto;
  ${({headerHeight}) =>
    headerHeight &&
    css`
      height: calc(100% - ${headerHeight}px);
    `}
`;

export {PageContent};
